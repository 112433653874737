<template>
    <my-drawer v-model="visible" :style="{width: '100%'}" :with-nav="false" class="NumberDetail" position="right">
        <my-alert title="号码使用状态">
            <template v-if="destineInfo" #message>
                <span v-if="destineInfo.orderStatus">
                    {{ destineInfo.orderStatus | getMapText('number/orderStatus') }}
                </span>
                <span v-else>未提单</span>
            </template>
        </my-alert>
        <my-table v-if="row">
            <my-table-row :value="row.number" text="号码" />
            <my-table-row :value="row.sectionCode" text="号段">
                <template #value>{{ row.sectionCode | getMapText('number/sections') }}</template>
            </my-table-row>
            <my-table-row :value="row.typeCode" text="号码类型" />
            <my-table-row :value="row.numClass" text="号码种类">
                <my-label slot="value"
                          background="#E6EFFF"
                          color="#1677FF"
                          size="mini"
                >
                    {{ row.numClass | getMapText('number/numClass') }}
                </my-label>
            </my-table-row>
            <my-table-row :value="row.callinMinFee" text="呼入月低消费" />
            <template v-if="destineInfo">
                <my-table-row :value="destineInfo.destineEndTime" text="预占到期时间" />
                <my-table-row :value="destineInfo.destineStaff" text="预占员工">
                    <span slot="value">
                        {{ destineInfo.destineStaff }}
                    </span>
                </my-table-row>
                <my-table-row :value="destineInfo.destineRemark" text="预占备注" />
            </template>
        </my-table>
        <template #footer>
            <van-button block type="default" @click="$router.back()">返回</van-button>
            <van-button v-if="occupiedState"
                        block
                        type="info"
                        @click="onRelease"
            >释放</van-button>
            <van-button v-if="destineInfo && (!destineInfo.orderStatus || destineInfo.orderStatus == 2)"
                        block
                        type="info"
                        @click="onOpenOrder"
            >开通</van-button>
        </template>
    </my-drawer>
</template>

<script>
    import {MyDrawer, MyTable, MyTableRow, MyLabel, MyAlert} from "@/components/MyPlugins";
    import {mapMutations, mapState} from "vuex";
    import {getNumberInfo, numberDestineInfo, preCancel} from "@/api/number";

    export default {
        name: "NumberDetail",
        components: {MyDrawer, MyTable, MyTableRow, MyLabel, MyAlert},
        data() {
            return {
                visible: false,
                row: {destineOrganCode: ""},
                destineInfo: null
            }
        },
        computed: {
            ...mapState('number', ['numClass', 'dataCube','sections']),
            ...mapState('user', ['accountInfo']),
            // 操作状态
            operateStatus() {
                if (!this.row) return null;
                let {operateStatus} = this.row;
                if (operateStatus && operateStatus.length > 0) {
                    if (operateStatus.length == 1) return operateStatus[0];
                }
                return 0;
            },
            // 本人已预占
            occupiedState() {
              return this.operateStatus == 4 && this.accountInfo.userCode == this.row.destineOrganCode;
            },
        },
        mounted() {
            this.visible = true;
            this.$nextTick(this.getNumberRow);
        },
        beforeRouteLeave(to, from, next) {
            this.visible = false;
            next();
        },
        methods: {
            ...mapMutations('common', ['updateRow']),
            onOpenOrder() {
                let {number, sectionCode} = this.$route.query;
                this.$router.push({path: '/open-order', query: {number, sectionCode}});
            },
            // 获取号码详情
            getNumberRow() {
                this.$toast.loading({message: '数据刷新中', forbidClick: true, duration: 0});
                let {number} = this.$route.query;
                getNumberInfo({number}).then(res => {
                    this.row = res.data;
                    // 查询预占信息
                    numberDestineInfo({number}).then(o => {
                        window.console.log(o.data);
                        this.destineInfo = o.data;
                        this.$toast.clear();
                    });
                });
            },
            // 更新列表当前选中行数据
            afterUpdate(status) {
                this.row.operateStatus = [status];
                this.updateRow(this.row);
                this.$router.replace({path: '/number-list'});
            },
            // 释放
            onRelease() {
                let {number} = this.row;
                let {orderStatus = 2} = this.destineInfo || {};
                let message = orderStatus == 1 ? '您已提单确定要释放吗？' : '确定要释放号码吗？';
                this.$confirm(message, '号码释放', {
                    placement: 'bottom',
                    beforeClose: (action, instance, done) => {
                        if (action == 'confirm') {
                            instance.confirmButtonLoading = true;
                            preCancel({number}).then(() => {
                                instance.confirmButtonLoading = false;
                                done();
                            }).catch(() => {
                                instance.confirmButtonLoading = false;
                            });
                        } else {
                            instance.confirmButtonLoading = false;
                            done();
                        }
                    }
                }).then(() => {
                    this.$toast({
                        message: '号码释放成功',
                        icon: 'checked',
                        onClose: () => {
                            this.afterUpdate(3);
                        }
                    });
                });
            }
        }
    }
</script>

<style scoped>

</style>
